import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

// constant
import theme from "../../constants/styledTheme";

const Button = (props) => {
  const { title, route, color, external } = props;

  const renderColor = () => {
    return theme.colors[color];
  };

  return (
    <Body backgroundColor={renderColor}>
      {external ? (
        <a href={route} target="_blank" className="button_style">
          {title}
        </a>
      ) : (
        <a className="button_style">{title}</a>
      )}
    </Body>
  );
};

const Body = styled.div`
  .button_style {
    text-decoration: none;
    cursor: pointer;
    color: #ffffff;
    max-height: 40px;
    font-weight: bold;
    font-family: Muli;
    font-size: 14px;
    background: ${({ backgroundColor }) => backgroundColor};
    line-height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    height: 36px;
    padding: 0px 10px;
    text-align: center;
    text-decoration: none;

    white-space: nowrap;
    outline: none;
    box-shadow: 5px 5px 0px 0px rgba(176, 176, 176, 0.74);
    -webkit-box-shadow: 5px 5px 0px 0px rgba(176, 176, 176, 0.74);
    -moz-box-shadow: 5px 5px 0px 0px rgba(176, 176, 176, 0.74);
  }
`;

export default Button;
