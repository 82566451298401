export const foodListPromo = [
  {
    title: "Promo Fruit 1",
    price: 10000,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Promo Fruit 2",
    price: 10000,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Promo Fruit 3",
    price: 10000,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Promo Fruit 4",
    price: 10000,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Promo Fruit 5",
    price: 10000,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Promo Fruit 6",
    price: 10000,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Promo Fruit 7",
    price: 10000,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
];

export const foodList = [
  {
    title: "Fruit 1",
    price: 10000,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Fruit 2",
    price: 10000,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Fruit 3",
    price: 10000,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Fruit 4",
    price: 10000,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Fruit 5",
    price: 10000,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Fruit 6",
    price: 10000,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Fruit 7",
    price: 10000,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
];

export const foodListFlashSale = [
  {
    title: "Bundling 1",
    price: 10000,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Bundling 2",
    price: 10000,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Bundling 3",
    price: 10000,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Bundling 4",
    price: 10000,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Bundling 5",
    price: 10000,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Bundling 6",
    price: 10000,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Bundling 7",
    price: 10000,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
];
