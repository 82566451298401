export default {
  colors: {
    black: "#333333",
    blackLight: "#858585",
    blackLighter: "#CCCCCC",
    whiteDarker: "#EFEFEF",
    whiteDark: "#F7F7F7",
    blueDark: "#006A7A",
    blue: "#00C1D4",
    blueLight: "#7BE2EA",
    blueLighter: "#D4F7F7",
    whiteBlue: "#D4F7F780",
    coralDark: "#CC2200",
    coral: "#FF6C47",
    coralLight: "#FFA391",
    coralLighter: "#FFEDEB",
    sun: "#FED559",
    tango: "#F47522",
    biscay: "#19345E",
    tolopea: "#0F044C",
    jacarta: "#512D6D",
    flamingo: "#F15025",
    honey: "#44CF6C",
    tosca: "#F47522",
    biruTosca: "#548CA8",
  },
  space: ["0.5rem", "1rem", "1.5rem", "2.5rem", "4rem", "6rem"],
  fontSizes: ["0.625rem", "0.75rem", "0.875rem", "1rem", "1.25rem", "2rem"],
  lineHeights: ["1rem", "1rem", "1.25rem", "1.5rem", "2rem", "2.5rem"],
};
