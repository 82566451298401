import React from "react";
import styled from "styled-components";

import { screen } from "../../constants/media";

const FoodCard = (props) => {
  const { title, price, description } = props;

  const nominalFormat = (nominal) => {
    return new Intl.NumberFormat("id").format(+(+nominal || 0).toFixed(0));
  };

  return (
    <Body>
      <div className="container">
        <div className="card">
          <img
            src="https://www.freepnglogos.com/uploads/burger-png/burger-png-png-images-yellow-images-12.png"
            className="card__img"
          />
          <div className="card__data">
            <h1 className="card__title">{title}</h1>
            <span className="card__price">Rp{nominalFormat(price)}</span>
            <p className="card__description">{description}</p>
            {/* <a href="#" className="card__button">
              Order Now!
            </a> */}
          </div>
        </div>
      </div>
    </Body>
  );
};

const Body = styled.div`
  display: table;
  box-sizing: border-box;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0;
  font-family: "Poppins", sans-serif;
  text-decoration: none;

  @media ${screen.desktop} {
    flex: 0 0 16%;
  }

  .container {
    @media ${screen.desktop} {
      height: 65vh;
    }
    height: 75vh;

    display: flex;
    justify-content: center;
    align-items: center;

    .card {
      background-color: #fff7bc;
      border-radius: 1rem;
      padding: 4rem 1rem 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 50vh;
      width: 120px;
      @media ${screen.desktop} {
        width: 100%;
        padding: 4rem 2rem 0;
      }

      .card__img {
        postion: absolute;
        width: 150px;
        filter: drop-shadow(5px 10px rgba(8, 9, 13, 0.2));
        transform: translateY(-6.5rem);
        @media ${screen.desktop} {
          transform: translateY(-8.5rem);
        }
      }

      .card__data {
        transform: translateY(-4.8rem);

        .card__title {
          font-size: 1.5rem;
          color: #002366;
          margin-bottom: 0.5rem;
        }

        .card__price {
          display: inline-block;
          font-size: 1.25rem;
          font-weight: 500;
          margin-bottom: 1rem;
        }

        .card__description {
          font-size: 1rem;
          margin-bottom: 1.25rem;
        }

        .card__button {
          display: block;
          widht: max-content;
          padding: 1.125rem 2rem;
          background-color: #002366;
          color: #fff;
          border-radius: 0.55rem;
          font-weight: 600;
        }
      }
    }
  }
`;

export default FoodCard;
