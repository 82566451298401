import styled from "styled-components";

//constant
import theme from "../../constants/styledTheme";

const Text = styled.div.attrs(({ color, family, size, weight, spacing }) => ({
  color: color || "biruTosca",
  family: family || "Muli",
  size: size || "18px",
  weight: weight || "normal",
  spacing: spacing || "normal",
}))`
  color: ${({ color }) => theme.colors[color]};
  font-family: ${({ family }) => family};
  font-size: ${({ size }) => size};
  font-weight: ${({ weight }) => weight};
  word-spacing: ${({ spacing }) => spacing};
`;

export default Text;
