import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

//component
import Button from "../Button/Button";

//constant
import { screen } from "../../constants/media";

const menu = [
  { label: "BERANDA", route: "/" },
  { label: "PROMO", route: "/promo" },
  { label: "FLASH SALE", route: "/flash-sale" },
];

const Header = () => {
  return (
    <>
      <Body>
        {menu.map(({ label, route }, index) => {
          return (
            <NavLink
              key={`nav-item-${index}`}
              exact
              to={route}
              activeClassName="navbar__link--active"
              className="label__style"
            >
              {label}
            </NavLink>
          );
        })}
        <div className="header__button">
          {/* <Button title="Hire Me!" route="contact" color="tango" /> */}
        </div>
      </Body>
    </>
  );
};

const Body = styled.div`
  border-bottom: 1px solid #bbbbbb;
  text-align: center;
  // .header__button{
  //   display: none;
  //   @media ${screen.tablet} {
  //     display: inline-block;
  //   }
  // }
  .label__style {
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    color: #031d44;
    padding: 15px 0px;
    margin: 0px 10px;
    display: inline-block;
    position: relative;
    opacity: 0.5;
    @media ${screen.tablet} {
      font-size: 20px;
      margin: 0px 25px;
    }
  }
  .label__style:hover {
    opacity: 1;
  }
  .label__style::before {
    transition: 300ms;
    content: "";
    position: absolute;
    background-color: #031d44;
    height: 0%;
    width: 100%;
    bottom: -5px;
  }
  .label__style:hover::before {
    height: 5px;
  }
  .navbar__link--active {
    border-bottom: 5px solid #031d44;
    transition: border-bottom .5s ease-in-out;
`;

export default Header;
