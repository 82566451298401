import React, { Component } from "react";
import styled from "styled-components";

//component
import Button from "../components/Button/index";
import Header from "../components/Header/index";
import { FoodCard } from "../components/Card";
import Text from "../components/Styles/Text";

//constant
import { screen } from "../constants/media";
import { foodListFlashSale } from "../constants/content";

class FlashSale extends Component {
  render() {
    return (
      <Container>
        <Header />
        <div className="food">
          {foodListFlashSale.map(({ title, price, description }) => {
            return (
              <FoodCard title={title} price={price} description={description} />
            );
          })}
        </div>
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  min-height: 100%;

  .food {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 50px auto;

    @media ${screen.desktop} {
      margin: 100px auto;
    }
  }
`;

const OutterContainer = styled.div`
  min-height: 100%;
  position: relative;
`;

export default FlashSale;
