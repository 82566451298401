import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

//pages
import Index from "../pages/Index";
import Promo from "../pages/Promo";
import FlashSale from "../pages/FlashSale";

const MainRoute = () => {
  return (
    // <Provider>
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Index} />
        <Route exact path="/promo" component={Promo} />
        <Route exact path="/flash-sale" component={FlashSale} />
      </Switch>
    </BrowserRouter>
    // </Provider>
  );
};

export default MainRoute;
